<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="dayOptions"
			:class="(validateError != '' || validateError != null)? '':'box-border-color'"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				default: null,
			},
            showNotAssigned:{
                default: false
            },

		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		data() {
			return {
				selected: null,
				options: [
					{value: 1, text: this.$t("monday")},
					{value: 2, text: this.$t("tuesday")},
					{value: 3, text: this.$t("wednesday")},
					{value: 4, text: this.$t("thursday")},
					{value: 5, text: this.$t("friday")},
					{value: 6, text: this.$t("saturday")},
					{value: 7, text: this.$t("sunday")},
                    {value: 8, text: this.$t("not_assigned")},
				],
			};
		},
		created() {
			this.selected = this.setSelected(this.value, this.options);
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
		},
        computed:{
            dayOptions(){
                if(!this.showNotAssigned){
                    return this.options.filter(item=>{
                        return item.value!=8;
                    });
                }
                return this.options;
            }
        }
	}
</script>
